import { augmentedOccasionsApi } from 'api'
import { InspirationDocumentDto } from 'types/dto/augmentedOccasionsDto'

export interface InspirationsParams {
  query: string
  tenantName: string
  tenantId: string
  documentsPerPage: number
}

export const fetchInspirations = ({ query, tenantName, tenantId, documentsPerPage }: InspirationsParams) =>
  augmentedOccasionsApi.get<InspirationDocumentDto[]>('/documents', {
    params: { query, tenantName, tenantId, documentsPerPage },
  })
